@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --plyr-color-main: linear-gradient(to top, #8e00d6, #af52de);
    --background: 0 0% 100%; /* neutral-50 */
    --foreground: 0 0% 3.9%; /* neutral-900 */

    --card: 0 0% 100%; /* neutral-50 */
    --card-foreground: 0 0% 10%; /* neutral-900 */

    --popover: 0 0% 100%; /* neutral-50 */
    --popover-foreground: 0 0% 10%; /* neutral-900 */

    --primary: 0 0% 20%; /* neutral-800 */
    --primary-foreground: 0 0% 98%; /* neutral-50 */

    --secondary: 0 0% 96%; /* neutral-100 */
    --secondary-foreground: 0 0% 20%; /* neutral-800 */

    --muted: 0 0% 90%; /* neutral-200 */
    --muted-foreground: 0 0% 40%; /* neutral-600 */

    --accent: 0 0% 96%; /* neutral-100 */
    --accent-foreground: 0 0% 20%; /* neutral-800 */

    --destructive: 0 84.2% 60.2%; /* red-500 */
    --destructive-foreground: 0 0% 98%; /* neutral-50 */

    --border: 0 0% 85%; /* neutral-300 */
    --input: 0 0% 85%; /* neutral-300 */
    --ring: 0 0% 10%; /* neutral-900 */
    --radius: 0.5rem;

    --chart-1: 12 76% 61%; /* Keep as is */
    --chart-2: 173 58% 39%; /* Keep as is */
    --chart-3: 197 37% 24%; /* Keep as is */
    --chart-4: 43 74% 66%; /* Keep as is */
    --chart-5: 27 87% 67%; /* Keep as is */
  }

  .dark {
    --background: 0 0% 5%; /* neutral-950 */
    --foreground: 0 0% 98%; /* neutral-50 */

    --card: 0 0% 10%; /* neutral-900 */
    --card-foreground: 0 0% 98%; /* neutral-50 */

    --popover: 0 0% 10%; /* neutral-900 */
    --popover-foreground: 0 0% 98%; /* neutral-50 */

    --primary: 0 0% 98%; /* neutral-50 */
    --primary-foreground: 0 0% 20%; /* neutral-800 */

    --secondary: 0 0% 20%; /* neutral-800 */
    --secondary-foreground: 0 0% 98%; /* neutral-50 */

    --muted: 0 0% 30%; /* neutral-700 */
    --muted-foreground: 0 0% 70%; /* neutral-300 */

    --accent: 0 0% 20%; /* neutral-800 */
    --accent-foreground: 0 0% 98%; /* neutral-50 */

    --destructive: 0 62.8% 30.6%; /* red-700 */
    --destructive-foreground: 0 0% 98%; /* neutral-50 */

    --border: 0 0% 25%; /* neutral-700 */
    --input: 0 0% 25%; /* neutral-700 */
    --ring: 0 0% 70%; /* neutral-300 */

    --chart-1: 220 70% 50%; /* Keep as is */
    --chart-2: 160 60% 45%; /* Keep as is */
    --chart-3: 30 80% 55%; /* Keep as is */
    --chart-4: 280 65% 60%; /* Keep as is */
    --chart-5: 340 75% 55%; /* Keep as is */
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  .max-container {
    @apply mx-auto max-w-[1440px];
  }
  .padding-container {
    @apply px-4 md:px-8 lg:px-16;
  }
  .tiptap {
    @apply dark:text-white;
  }
  .tiptap ul li::before {
    @apply dark:text-white;
  }
}

@layer components {
  .no-spinner {
    -moz-appearance: textfield;
  }

  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  .bg-counter {
    background-image: url("../public/images/bg-counter.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@layer components {
  .bg-upload-video {
    background-image: url("../public/images/bg-upvideo.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@layer components {
}

/* filepond stylesheet */

/* filepond */

/* use a hand cursor intead of arrow for the action buttons */
.filepond--file-action-button {
  border: none !important;
  cursor: pointer;
}

/* the text color of the drop label*/

/* underline color for "Browse" button */
.filepond--label-action {
  text-decoration-color: #aaa;
}

/* the border radius of the drop area */
.filepond--panel-root {
  border-radius: 0.5em;
}

/* the border radius of the file item */
.filepond--item-panel {
  border-radius: 0.5em;
}

/* the background color of the file and file panel (used when dropping an image) */

/* the background color of the drop circle */
.filepond--drip-blob {
  background-color: #0400ff;
}

/* the background color of the black action buttons */
.filepond--file-action-button {
  background-color: rgba(0, 0, 0, 0.5);
}

/* the icon color of the black action buttons */
.filepond--file-action-button {
  color: rgb(78, 78, 78);
}

/* the color of the focus ring */
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}

/* the text color of the file status and info labels */

/* error state color */
[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  background-color: red;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  background-color: green;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
  background-color: #969696;
}

/* Basic editor styles */
.tiptap:first-child {
  margin-top: 0;
}

/* List styles */
.tiptap ul {
  padding: 0 1rem;
  margin: 1.25rem 1rem 1.25rem 0.4rem;
  list-style-type: none; /* Remove default bullets */
}

.tiptap ul li {
  position: relative;
  padding-left: 1.5rem; /* Space for the custom bullet */
}

.tiptap ul li::before {
  content: "•"; /* Unicode bullet character */
  position: absolute;
  left: 0;
  color: black; /* Bullet color */
  font-size: 1rem; /* Bullet size */
  line-height: 1; /* Align with text */
}

.tiptap ol {
  padding: 0 1rem;
  margin: 1.25rem 1rem 1.25rem 0.4rem;
}

.tiptap ol li {
  list-style-position: inside; /* Ensures the numbers are inside the padding */
  color: black; /* Number color */
}

.tiptap ol li::marker {
  color: black; /* Number color */
}
.tiptap h1 {
  font-size: 2.25rem; /* text-4xl */
  font-weight: 700; /* font-bold */
  line-height: 2.5rem; /* line-height 4xl */
}

.tiptap h2 {
  font-size: 1.875rem; /* text-3xl */
  font-weight: 700; /* font-bold */
  line-height: 2.25rem; /* line-height 3xl */
}

.tiptap h3 {
  font-size: 1.5rem; /* text-2xl */
  font-weight: 700; /* font-bold */
  line-height: 2rem; /* line-height 2xl */
}

.tiptap h4 {
  font-size: 1.25rem; /* text-xl */
  font-weight: 700; /* font-bold */
  line-height: 1.75rem; /* line-height xl */
}

.tiptap h5 {
  font-size: 1.125rem; /* text-lg */
  font-weight: 700; /* font-bold */
  line-height: 1.75rem; /* line-height lg */
}

.tiptap h6 {
  font-size: 1rem; /* text-base */
  font-weight: 700; /* font-bold */
  line-height: 1.5rem; /* line-height base */
}

/* Code and preformatted text styles */
.tiptap code {
  background-color: #c4b5fd;
  border-radius: 0.4rem;
  color: #272727;
  font-size: 0.85rem;
  padding: 0.25em 0.3em;
}

.tiptap pre {
  background: #1e1b4b;
  border-radius: 0.5rem;
  color: #1e1b4b;
  font-family: "JetBrainsMono", monospace;
  margin: 1.5rem 0;
  padding: 0.75rem 1rem;
}

.tiptap pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.tiptap blockquote {
  border-left: 3px solid #7c3aed;
  margin: 1.5rem 0;
  padding-left: 1rem;
  background: #ede9fe;
  color: #7e22ce;
}

.tiptap hr {
  border: none;
  border-top: 1px solid #cbd5e1;
  margin: 2rem 0;
}

/* Basic editor styles */
.tiptap:first-child {
  margin-top: 0;
}

/* Link styles */
.tiptap a {
  color: #7e22ce;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.tiptap a:hover {
  color: #51018a;
}

@layer utilities {
  /* =====  SHADCN OVERRIDES */
  .shad-primary-btn {
    @apply bg-green-500 text-white !important;
  }

  .shad-danger-btn {
    @apply bg-red-700 text-white !important;
  }

  .shad-gray-btn {
    @apply cursor-pointer border  bg-background text-white !important;
  }

  .shad-input-label {
    @apply text-14-medium text-dark-700 !important;
  }

  .shad-input {
    @apply h-10  bg-background placeholder:text-slate-500 focus-visible:ring-0 focus-visible:ring-offset-0 !important;
  }

  .shad-input-icon {
    @apply h-10 border bg-background placeholder:text-slate-500 focus-visible:ring-0 focus-visible:ring-offset-0 !important;
  }

  .shad-textArea {
    @apply border bg-background placeholder:text-slate-500 focus-visible:ring-0 focus-visible:ring-offset-0 !important;
  }

  .shad-combobox-item {
    @apply data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50 !important;
  }

  .shad-combobox-trigger {
    @apply h-10 !important;
  }

  .shad-select-trigger {
    @apply h-10  border bg-background placeholder:text-slate-500 focus:ring-0 focus:ring-offset-0 !important;
  }

  .shad-select-content {
    @apply border bg-background !important;
  }

  .shad-dialog {
    @apply border bg-background !important;
  }

  .shad-dialog button {
    @apply focus:ring-0 focus:ring-offset-0 focus-visible:border-none focus-visible:outline-none focus-visible:ring-transparent focus-visible:ring-offset-0 !important;
  }

  .shad-error {
    @apply text-red-400 !important;
  }

  .shad-table {
    @apply overflow-hidden rounded-lg !important;
  }

  .shad-table-row-header {
    @apply text-light-200 border-b border-background hover:bg-transparent !important;
  }

  .shad-table-row {
    @apply text-light-200 border-b border-background !important;
  }

  .shad-otp {
    @apply flex w-full justify-between !important;
  }

  .shad-otp-slot {
    @apply text-36-bold  flex size-16 justify-center gap-4 rounded-lg border !important;
  }

  .shad-alert-dialog {
    @apply space-y-5 border bg-background outline-none !important;
  }

  .shad-sheet-content button {
    @apply top-2 focus:ring-0 focus:ring-offset-0 focus-visible:border-none focus-visible:outline-none focus-visible:ring-transparent focus-visible:ring-offset-0 !important;
  }
}
